.mbsc-ios-dark .mbsc-fr-btn {
  color: #32cd32 !important;
}

.mbsc-ios-dark .mbsc-cal-today {
  color: #32cd32 !important;
}

.mbsc-ios-dark
  .mbsc-cal-c
  .mbsc-cal
  .mbsc-cal-body
  .mbsc-cal-row
  .mbsc-selected
  .mbsc-cal-cell-txt {
  border-color: #32cd32 !important;
  background: #32cd32 !important;
}

.mbsc-ios.mbsc-card.mbsc-form {
  margin: 0px !important;
  border-radius: 0px !important;
}

.mbsc-form-group-title {
  font-size: 15px !important;
}
